<style scoped>
.bmap {
  width: 100%;
  height: 480px;
}
</style>

<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>经纬度选择</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <baidu-map
        v-if="show"
        :center="mapCenter"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        :mapStyle="mapStyle"
        class="bmap"
        @ready="onReady"
        @click="onClick"
      >
        <bm-marker :position="position"></bm-marker>
      </baidu-map>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="select">确定</v-btn>
        <v-btn text @click="close">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mapStyle from "../utils/map-style.json";
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, center: String },
  data() {
    return {
      mapStyle: {
        styleJson: mapStyle,
      },
      ready: false,
      mapCenter: { lng: 0, lat: 0 },
      position: { lng: 0, lat: 0 },
      zoom: 11,
      map: null,
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.change();
      },
    },
  },
  destroyed() {
    this.clean();
  },
  methods: {
    clean() {
      if (this.map) this.map.clearOverlays();
      if (window.$BAIDU$) {
        if (window.$BAIDU$._instances) {
          let keys = Object.keys(window.$BAIDU$._instances);
          for (let i in keys) {
            let key = keys[i];
            let instance = window.$BAIDU$._instances[key];
            if (instance == this.map) {
              delete window.$BAIDU$._instances[key];
              if (window.MPC_Mgr) delete window.MPC_Mgr[key];
              if (window.GCJMAP && window.GCJMAP.ba == key)
                delete window.GCJMAP;
            } else if (instance.map == null) {
              delete window.$BAIDU$._instances[key];
            }
          }
        }
      }
      this.map = null;
    },
    onReady(e) {
      this.map = e.map;
      this.ready = true;
      this.change();
    },
    onClick(e) {
      this.position.lng = e.point.lng;
      this.position.lat = e.point.lat;
    },
    change() {
      if (!this.ready) return;
      let lnglat = this.center.split(",");
      let lng = parseFloat(lnglat[0]) || 116.40354;
      let lat = parseFloat(lnglat[1]) || 39.923425;
      this.mapCenter.lng = lng;
      this.mapCenter.lat = lat;
      this.position.lng = lng;
      this.position.lat = lat;
    },
    select() {
      this.$emit("select", `${this.position.lng},${this.position.lat}`);
      this.$emit("change", false);
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>