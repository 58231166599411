<template>
  <v-text-field
    :label="label"
    :value="`${value.length}项`"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="showDialog">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>地图坐标配置</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="add"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-list v-if="items.length">
            <v-list-item v-for="item in items" :key="item.uuid">
              <v-list-item-content>
                <v-text-field
                  label="设备"
                  :value="item.device.name"
                  readonly
                  dense
                  hide-details
                >
                  <template slot="append">
                    <v-btn
                      icon
                      tile
                      x-small
                      @click="
                        editModel = item;
                        deviceShow = true;
                      "
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  label="经纬度"
                  v-model="item.lonlat"
                  dense
                  hide-details
                >
                  <template slot="append">
                    <v-btn
                      icon
                      tile
                      x-small
                      @click="
                        editModel = item;
                        editCenter = item.lonlat;
                        editShow = true;
                      "
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="remove(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-container v-else>
            <h3>没有地图坐标项</h3>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <MapPointSelector
        v-model="editShow"
        :center="editCenter"
        @select="pointSelect"
      ></MapPointSelector>
      <ObjectSelector
        v-model="deviceShow"
        type="device"
        :single="true"
        @select="deviceSelect"
      ></ObjectSelector>
    </template>
  </v-text-field>
</template>

<script>
import uuid from "../../utils/uuid";
import MapPointSelector from "../../components/MapPointSelector.vue";
import ObjectSelector from "../../components/ObjectSelector.vue";

export default {
  components: { MapPointSelector, ObjectSelector },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      items: [],
      show: false,
      editShow: false,
      editCenter: "",
      editModel: null,
      deviceShow: false,
    };
  },
  methods: {
    showDialog() {
      this.items = JSON.parse(JSON.stringify(this.value));
      this.show = true;
    },
    pointSelect(lonlat) {
      if (this.editModel) this.editModel.lonlat = lonlat;
    },
    deviceSelect(item) {
      if (this.editModel) this.editModel.device = item;
    },
    add() {
      this.items.push({
        uuid: uuid(),
        device: { id: "", name: "未选择" },
        lonlat: "116.40354,39.923425",
      });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      this.change(this.items);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
